.introdiv {
  background-color: rgb(10, 10, 10);
  padding: 5%;
  margin-left: 192px;
  padding-bottom: 1px;
  font-family: 'Roboto', sans-serif;
}

.introdiv h3 {
  font-size: 3vw;
  color: aliceblue;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font: 'Roboto';
}

.introdiv h2 {
  font-size: 2vw;
  color: aliceblue;
  text-align: center;
  padding: 0 0 40px 0;
  border-bottom: 2px solid aliceblue;
}

.introdiv p {
  font-size: 1.75vw;
  color: aliceblue;
  text-align: center;
  padding: 0 0 40px 0;
  margin-top: 2.5%;
  margin-left: 10%;
  margin-right: 10%;
}

.introdivfull {
  background-color: rgb(10, 10, 10);
  padding: 5%;
  margin-top: 0;
  padding-bottom: 1px;
}

.introdivfull h3 {
  font-size: 3vw;
  color: aliceblue;
  text-align: center;
}

.introdivfull h2 {
  font-size: 2vw;
  color: aliceblue;
  text-align: center;
  padding: 0 0 40px 0;
  border-bottom: 2px solid aliceblue;
}

.introdivfull p {
  font-size: 1.75vw;
  color: aliceblue;
  text-align: center;
  padding: 0 0 40px 0;
  margin-top: 2.5%;
  margin-left: 10%;
  margin-right: 10%;
}

@media screen and (max-width: 700px) {
  .introdivfull p, .introdivfull h2, .introdivfull h3 {
     font-size: 15px;
  }
}
