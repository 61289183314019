.item {
  margin: 1rem 0;
}

.image {
  max-width: 100%;
  overflow: hidden;
}

.image img {
  width: 100%;
  object-fit: contain;
}

.content {
  text-align: center;
  padding: 1rem;
}

.content h3 {
  font-size: 2.5rem;
  color: #2c292b;
}

.content p {
  font-size: 1.5vw;
  color: #2c292b;
}

@media screen and (max-width: 700px) {
  .content p {
     font-size: 15px;
  }
}

.actions {
  padding: 1.5rem;
  text-align: center;
}

.actions button {
  font: inherit;
  cursor: pointer;
  color: #77002e;
  border: 1px solid #77002e;
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
}

.actions button:hover,
.actions button:active {
  background-color: #ffe2ed;
}

body {
  --primary-color: ;
  font-family: "Roboto", sans-serif;
}

.background-red {
  background-color: red;
}

.background-blue {
  background-color: blue;
}

.divbackground {
  padding-top: 1px;
  background-color: var(--primary-color);
  margin-left: 192px;
  justify-content: left;
  width: auto;
  text-align: center;
  padding-bottom: 1px;
}

.divbackgroundfull {
  padding-top: 1px;
  background-color: var(--primary-color);
  width: auto;
  text-align: center;
  padding-bottom: 1px;
}

.section {
  margin-top: 60px;
  margin-bottom: 60px;
}

.section h1 {
  color: aliceblue;
  font-size: 2.75rem;
  border-bottom: 2px solid rgb(224, 224, 224);
  margin-left: 30%;
  margin-right: 30%;
}
