.footer {
    background-color: rgb(10, 10, 10);
    padding: 5%;
    margin-left: 192px;
    padding-bottom: 100px;
    justify-content: space-around;
    display: flex;
}

@media screen and (max-width: 700px) {
    .footer {
        margin-left: 0px;
    }
  }

.footer p {
    color: rgb(200, 200, 200);
    font-size: 15px;
    font-family: "Roboto", sans-serif;
}

.underlined{
    text-decoration: underline;
}

.footer h4 {
    color: rgb(200, 200, 200);
}

.footer a {
    color: rgb(200, 200, 200);
    font-size: 15px;
}

.footer a:hover {
    color: rgb(255, 255, 255);
}

.footer p:hover {
    color: rgb(255, 255, 255);
}

.footer h2 {
    color: rgb(255, 255, 255);
}

.sidelist {
    display: flex;
}