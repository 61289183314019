body {
  background-color: #000000;
}

.test {
  height: 100%;
  margin: 0;
}

.divbackground {
  padding-top: 1px;
  background-color: rgb(92, 92, 92);
  margin-left: 192px;
  margin-right: 0%;
  justify-content: left;
  width: auto;
  text-align: center;
  padding-bottom: 1px;
}

.divbackground2 {
  padding-top: 1px;
  background-color: rgb(255, 118, 118);
  margin-left: 192px;
  margin-right: 0%;
  justify-content: left;
  width: auto;
  text-align: center;
  padding-bottom: 1px;
}

.divbackground3 {
  border: none !important;
}

.divholder {
  width: auto;
}

.section {
  margin-top: 60px;
  margin-bottom: 60px;
}
