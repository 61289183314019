.card {
  background-color: white;
  margin: 3rem auto;
  max-width: 60vw;
}

@media screen and (min-width: 1000px) {
  .card {
    max-width: 40vw;
  }
}

@media screen and (max-width: 700px) {
  .card {
    max-width: 90vw;
  }
}
